const buttonOpen = document.getElementById('button-open-menu');
const buttonClose = document.getElementById('button-close-menu');

function toggleMenu() {
    document.getElementById('navigation').classList.toggle('active');
}

buttonOpen.addEventListener('click', function (event) {
    toggleMenu();
}, false);

buttonClose.addEventListener('click', function (event) {
    toggleMenu();
}, false);
